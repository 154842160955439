import React from "react";

import Slider from "react-slick";
import UeSvg from "../../assets/img/ueSvg";

import Layout from "../../components/layout";
import SmallBanner from "../../components/smallBanner";

const DotationUE = () => {
  const lang = "de";

  const settings = {
    arrows: false,
    dots: true,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 578,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Layout
      seoTitle="EU-Zuschüsse"
      lang={lang}
      translationPL="/dotacja-ue/"
      translationEN="/en/eu-grants/"
    >
      <SmallBanner title="EU-Zuschüsse" lang={lang} />
      <section className="dotation-ue-info">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-4">
              <h2 className="color--main mb-40">POIE Untermaßnahme 1.1.1</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-7 col-xxl-8 offset-lg-6 offset-xl-5 offset-xxl-4">
              <UeSvg viewBox="46 0 670.6 181.4" />
              <p className="mb-30">
              Das Unternehmen Palettenwerk Kozik Sp. z o.o. führt ein aus den europäischen Fonds kofinanziertes Projekt "Entwicklung der innovativen Technologie der Modulbauweise, vorgefertigt auf der Basis von Holz durch Palettenwerk Kozik Sp. z o.o." durch.
              </p>
              <p className="mb-30">
              Ziel des Projektes ist es, eine Technologie der modularen, vorgefertigten Holzbauweise zu entwickeln und zu implementieren und damit das Innovationsniveau durch die Nutzung der Ergebnisse von F&E-Arbeiten deutlich zu verbessern.
              </p>
              <p>Das Projekt wird von der EU mitfinanziert in Höhe von: 9.113.867,55 PLN</p>
            </div>
          </div>
        </div>
      </section>
      <section className="dotation-ue-info dotation-ue-info--second">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-4">
              <h2 className="color--main mb-40">ROPWK Maßnahme 2.5</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-7 col-xxl-8 offset-lg-6 offset-xl-5 offset-xxl-4">
              <img
                className="mb-60"
                src={require("../../assets/img/dotation-ue2.png")}
                alt="Paletten"
              />
              <p className="mb-30">
              Die Gesellschaft Palko Spółka z ograniczoną odpowiedzialnością führt ein aus den Europäischen Fonds kofinanziertes Projekt "Kauf von innovativen Maschinen und Anlagen für die Produktion in der Firma Palko" durch.
              </p>
              <p className="mb-30">
              Das Projekt setzt die Einführung von Produkt-, Technologie-, Organisations- und Marketingveränderungen innovativer Art voraus. Die genannten Änderungen werden zum Erwerb fortschrittlicher neuer Technologien und zur Umsetzung von F&E-Ergebnissen führen.
              </p>
              <p>Das Projekt wird von der EU mitfinanziert in Höhe von: 888.505,25 PLN</p>
            </div>
          </div>
        </div>
      </section>
      <section className="timeline timeline--small">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-4">
              <h2 className="color--main mb-20">Unsere Projekte</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Slider {...settings} className="timeline__slider">
                <div className="slider__item">
                  <div
                    className="item__image"
                    style={{
                      backgroundImage: `url(${require("../../assets/img/realizations1.jpg")})`,
                    }}
                  ></div>
                </div>
                <div className="slider__item">
                  <div
                    className="item__image"
                    style={{
                      backgroundImage: `url(${require("../../assets/img/realizations2.jpg")})`,
                    }}
                  ></div>
                </div>
                <div className="slider__item">
                  <div
                    className="item__image"
                    style={{
                      backgroundImage: `url(${require("../../assets/img/realizations3.jpg")})`,
                    }}
                  ></div>
                </div>
                <div className="slider__item">
                  <div
                    className="item__image"
                    style={{
                      backgroundImage: `url(${require("../../assets/img/realizations4.jpg")})`,
                    }}
                  ></div>
                </div>
                <div className="slider__item">
                  <div
                    className="item__image"
                    style={{
                      backgroundImage: `url(${require("../../assets/img/realizations1.jpg")})`,
                    }}
                  ></div>
                </div>
                <div className="slider__item">
                  <div
                    className="item__image"
                    style={{
                      backgroundImage: `url(${require("../../assets/img/realizations2.jpg")})`,
                    }}
                  ></div>
                </div>
                <div className="slider__item">
                  <div
                    className="item__image"
                    style={{
                      backgroundImage: `url(${require("../../assets/img/realizations3.jpg")})`,
                    }}
                  ></div>
                </div>
                <div className="slider__item">
                  <div
                    className="item__image"
                    style={{
                      backgroundImage: `url(${require("../../assets/img/realizations4.jpg")})`,
                    }}
                  ></div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default DotationUE;
